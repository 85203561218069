.magicfeedback-container {
  margin-bottom: 10px;
  width: 100%;
}

.magicfeedback-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.magicfeedback-questions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.magicfeedback-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
}

.magicfeedback-label {
  margin-bottom: 25px;
  margin-top: 20px;
  font-weight: 700;
  font-family: inherit;
  font-size: 20px;
  color: #212529;
  text-align: start;
  word-wrap: break-word;
  white-space: normal;

}

.magicfeedback-checkbox {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.magicfeedback-checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.magicfeedback-radio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 5px;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 3px #ccc;
}

.magicfeedback-radio-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px;
}

.magicfeedback-checkbox input[type="checkbox"] {
  margin-right: 5px;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}


.magicfeedback-checkbox label,
.magicfeedback-radio-container label {
  margin-left: 5px;
  font-weight: 400;
  font-family: inherit;
  font-size: 16px;
  color: #212529;
  text-align: start;
  width: 100%;
}

.magicfeedback-container p {
  margin-bottom: 0px;
  font-size: 12px;
}

.magicfeedback-container h2 {
  line-height: 1.66;
  margin: 0;
  padding: 0;
  font-weight: 900;
  color: #222;
  font-family: 'Montserrat';
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
}

.magicfeedback-container h6 {
  margin-bottom: 1rem;
}

.magicfeedback-container input[type="date"],
.magicfeedback-container input[type="text"],
.magicfeedback-container input[type="number"],
.magicfeedback-container input[type="email"],
.magicfeedback-container input[type="password"],
.magicfeedback-container textarea,
.magicfeedback-container select {
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  border: 2px solid #ccc; /* Default border color - you can customize this */
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: #fff;
  color: #212529;
}

.magicfeedback-container input[type="radio"] {
  padding: 20px;
  margin: 10px 0 10px 15px;
  width: 40px;
  background-color: #fff;
  box-shadow: none;
}

/* Style for focused input/select */
.magicfeedback-container input:focus, select:focus {
  border-color: #4CAF50; /* Highlight color when focused - you can customize this */
}

/* Style for invalid input (example: validation error) */
.magicfeedback-container input.error, select.error {
  border-color: #ff3333; /* Red color for error state - you can customize this */
}

.magicfeedback-action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.magicfeedback-action-container .magicfeedback-submit {
  cursor: pointer;
  padding: 10px;
  margin: 10px 0;
  width: 100px;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: #1E293B;
  color: white;
}

.magicfeedback-action-container .magicfeedback-back {
  cursor: pointer;
  padding: 10px;
  margin: 10px 0;
  width: 100px;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
  background-color: transparent;
  color: #212529;
}

.magicfeedback-action-container .magicfeedback-submit:hover {
  background-color: #1E293B;
}

.magicfeedback-action-container .magicfeedback-back:hover {
  shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-color: #1E293B;
  color: white;
}


/* Rating styles */
.magicfeedback-rating {
  width: 100%;
}

.magicfeedback-rating-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;

  margin: 15px 5px;
  padding: 0;

  list-style: none;
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.magicfeedback-rating-option {
  margin: 0 0.5rem;
  padding: 0;
}

@media (max-width: 1024px) {
  .magicfeedback-rating-option {
    margin: 10px 0;
    padding: 0;
    width: 20%;
    display: flex;
    justify-content: center;
  }
}


.magicfeedback-rating-option-label-container {
  display: flex;
  flex-direction: column;
}


.magicfeedback-rating-option-label-container label {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  color: #475569;
}

.magicfeedback-rating-option-label-container input[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.magicfeedback-rating-option-label-container input[type=radio] + img {
  cursor: pointer;
  height: 48px;
  width: 48px;
}

/* LABEL STYLES */
.magicfeedback-rating-option-label-container input[type=radio] + label {
  cursor: pointer;
}

/* CHECKED STYLES */
.magicfeedback-rating-option-label-container input[type=radio]:checked + img {
  border: 2px solid #007bff;
  border-radius: 50%;
}
