@keyframes glitter {
  0%, 100% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

.glitter-effect {
  position: relative;
}

.glitter-effect::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: glitter 1s infinite;
  pointer-events: none;
}
